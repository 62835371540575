/* eslint-disable */

import React, { memo, useEffect, useRef, useState } from 'react'
import { useRedPush } from '../../../../../network-hooks/red-push/redPush'
import {
  monitorInfoInitialState,
  useOthervitalsStore,
  syringeStore,
} from '../../../../../store/vitalsStore'
import { useLocation, useParams } from 'react-router-dom'
import { appTheme } from '@/config/vitalSingsTheme'
import {
  CategoryAxis,
  ECoordinateMode,
  EHorizontalAnchorPoint,
  EllipsePointMarker,
  EVerticalAnchorPoint,
  FastLineRenderableSeries,
  NumberRange,
  NumericAxis,
  RightAlignedOuterVerticallyStackedAxisLayoutStrategy,
  SciChartSurface,
  TextAnnotation,
  Thickness,
  XyDataSeries,
} from 'scichart'
// import {SciChartSurface} from "scichart/Charting/Visuals/SciChartSurface";

import {
  ChartWrapper,
  IbRow1,
  IbRow1Col1,
  IbRow1Col2,
  IbRow2,
  IbRow2Col1,
  IbRow2Col2,
  InfoBox,
} from './patientMonitoring.styles'
import { vitalSignsEcgData } from '@/constant/vitals.data'
import Icon from '@/components/global/icon/Icon'
import theme from '@/components/global/theme'
import { toggleFullscreen } from '@/utils'

const divElementId = 'chart'
const STEP = 256;
// const RESP_STEPS = 48;
// const PLETH_STEPS = 128;
const PLETH_TIMER_MS = 175;
const TIMER_TIMEOUT_MS = 75;
const RESP_TIMEOUT_MS = 200;
const STROKE_THICKNESS = 1;
const POINTS_LOOP = 1500;
const RESP_POINTS_LOOP = 1500;
const GAP_POINTS = 50;

const ECG_TIMEOUT = 40;
const PLETH_TIMEOUT = 60;
const RESP_TIMEOUT = 90;
const ECG_STEPS = 10;
const PLETH_STEPS = 10;
const RESP_STEPS = 6;


const { ecgPubnubValues, ecgPubnubValues1, respPubnubData, plethPubnubData } =
  vitalSignsEcgData

  import { dummyData, monitorDummyData } from '@/constant/vitals.data';

const getRespValuesFromData = (xIndex: number, vitalData: any) => {
  const ecgData = vitalData?.ecgData || []
  const respData = vitalData?.respData || []
  const plethData = vitalData?.plethData || []
  const xArr: number[] = []
  const ecgXArray: number[] = []
  const respXArray: number[] = []
  const plethXArray: number[] = []
  const ecgHeartRateArr: number[] = []
  const bloodPressureArr: number[] = []
  const bloodVolumeArr: number[] = []
  const bloodOxygenationArr: number[] = []
  if (respData.length) {
    for (let i = respRead; i< respRead + RESP_STEPS && i < respData.length; i++) {
      const x = i
      xArr.push(x)
      if (i < respData.length) {
        respXArray.push(x)
        bloodPressureArr.push(respData[i] / 5000)
      }
    }
  }
  return {
    ecgXArray,
    respXArray,
    plethXArray,
    xArr,
    ecgHeartRateArr,
    bloodPressureArr,
    bloodVolumeArr,
    bloodOxygenationArr,
  }
}

const getPlethValuesFromData = (xIndex: number, vitalData: any) => {
  const ecgData = vitalData?.ecgData || []
  const respData = vitalData?.respData || []
  const plethData = vitalData?.plethData || []
  const xArr: number[] = []
  const ecgXArray: number[] = []
  const respXArray: number[] = []
  const plethXArray: number[] = []
  const ecgHeartRateArr: number[] = []
  const bloodPressureArr: number[] = []
  const bloodVolumeArr: number[] = []
  const bloodOxygenationArr: number[] = []
  if (plethData.length) {
    for (let i = plethRead; i< plethRead + PLETH_STEPS && i < plethData.length; i++) {
      const x = i;
      xArr.push(x)
      if (i < plethData.length) {
        plethXArray.push(x)
        bloodVolumeArr.push(plethData[i] / 8000)
      }
    }
  }
  return {
    ecgXArray,
    respXArray,
    plethXArray,
    xArr,
    ecgHeartRateArr,
    bloodPressureArr,
    bloodVolumeArr,
    bloodOxygenationArr,
  }
}

const getValuesFromData = (xIndex: number, vitalData: any) => {
  const ecgData = vitalData?.ecgData || []
  const respData = vitalData?.respData || []
  const plethData = vitalData?.plethData || []
  const xArr: number[] = []
  const ecgXArray: number[] = []
  const respXArray: number[] = []
  const plethXArray: number[] = []
  const ecgHeartRateArr: number[] = []
  const bloodPressureArr: number[] = []
  const bloodVolumeArr: number[] = []
  const bloodOxygenationArr: number[] = []
  if (ecgData.length) {
    for (let i = ecgRead; i < ecgRead + ECG_STEPS && i < ecgData.length; i++) {
      const x = i;
      xArr.push(x)
      if (i < ecgData.length) {
        ecgXArray.push(x)
        ecgHeartRateArr.push(ecgData[i]/8000)
      }
    }
  }
  return {
    ecgXArray,
    respXArray,
    plethXArray,
    xArr,
    ecgHeartRateArr,
    bloodPressureArr,
    bloodVolumeArr,
    bloodOxygenationArr,
  }
}


let currentPoint = 0
let currentRespPoint = 0
let ecgRead = 0;
let ecgWrite = 0;
let respRead = 0;
let respWrite =0;
let plethRead =0;
let plethWrite =0;

const _PatientMonitoring: React.FC<any> = ({orderId}: any) => {
  const sciChartSurfaceRef = React.useRef<SciChartSurface>()
  const pusher = useRedPush('VITAL_SIGNS')
  // const { orderId = '' } = useParams()
  const [monitoringinfo, setMonitoringInfo] = useState(monitorInfoInitialState)
  const [ecg1Data, setEcgData] = useState<any>(ecgPubnubValues)
  const ecgdataCopy = useRef<any>([])
  const respDataCopy = useRef<any>([])
  const plethDataCopy = useRef<any>([])
  const [respWave, setRespWave] = useState<any>(respPubnubData)
  const [plethWave, setPlethWave] = useState<any>(plethPubnubData)
  const controlsRef = React.useRef<{
    handleStart: (vitalData: any) => void
    handleRespStart: (vitalData: any) => void
    handlePlethStart: (vitalData: any) => void
    handleStop: () => void
    handleRespStop: () => void
    handlePlethStop: () => void
    runUpdateDataOnTimeout: (vitalData: any) => void
  }>()

  let ecgTimerId: NodeJS.Timeout | undefined;
  let plethTimer: NodeJS.Timeout | undefined;
  let respTimer: NodeJS.Timeout | undefined;

  const [infoEcg, setInfoEcg] = React.useState<number>(0)
  const [infoBloodPressure1, setInfoBloodPressure1] = React.useState<number>(0)
  const [infoBloodPressure2, setInfoBloodPressure2] = React.useState<number>(0)
  const [infoBloodVolume, setInfoBloodVolume] = React.useState<number>(0)
  const [infoBloodOxygenation, setInfoBloodOxygenation] =
    React.useState<number>(0)
  const { setOtherVitalsState } = useOthervitalsStore((state) => {
    return { ...state }
  })
  const { setSyringePumpState } = syringeStore((state) => {
    return { ...state }
  })


  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const patientOrderId = orderId ?? '' ;
  // const patientOrderId = 'ORD123456' ;

  useEffect(()=> {

    ecgdataCopy.current = [];
    respDataCopy.current = [];
    plethDataCopy.current = [];
    ecgWrite =0;
    ecgRead =0;
    respRead = 0;
    respWrite =0;
    plethRead =0;
    plethWrite =0;

  }, []);



  const drawExample = async () =>

  {
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(
      divElementId,
      {
        theme: {
          ...appTheme.SciChartJsTheme,
          sciChartBackground: theme.colors['bg-black'],
        },
      }
    )

    // Create a single, shared X-axis, pre-sized to fit the data in X, and is invisible

    // Note: For fifoSweeping mode to work, the X-Axis must be a CategoryAxis
    //      NumericAxis is also supported, but x-values must then be offsets from 0, ie do x % fifoCapacity.
    //      See more info in the docs
    const xAxis = new CategoryAxis(wasmContext, {
      visibleRange: new NumberRange(0, POINTS_LOOP),
      isVisible: false,
    })
    sciChartSurface.xAxes.add(xAxis)

    // Create multiple y-axis, one per trace. Using the stacked vertically layout strategy
    const yAxisHeartRate = new NumericAxis(wasmContext, {
      id: 'yHeartRate',
      visibleRange: new NumberRange(1.0, 1.1),
      isVisible: false,
    })
    const yAxisBloodPressure = new NumericAxis(wasmContext, {
      id: 'yBloodPressure',
      visibleRange: new NumberRange(0.4, 1.1),
      isVisible: false,
    })
    const yAxisBloodVolume = new NumericAxis(wasmContext, {
      id: 'yBloodVolume',
      visibleRange: new NumberRange(0.4, 1.0),
      isVisible: false,
    })
    const yAxisBloodOxygenation = new NumericAxis(wasmContext, {
      id: 'yBloodOxygenation',
      visibleRange: new NumberRange(0, 0.2),
      isVisible: false,
    })
    sciChartSurface.layoutManager!.rightOuterAxesLayoutStrategy =
      new RightAlignedOuterVerticallyStackedAxisLayoutStrategy()
    sciChartSurface.yAxes.add(
      yAxisHeartRate,
      yAxisBloodPressure,
      yAxisBloodVolume,
      yAxisBloodOxygenation
    )

    // Using the NEW fifoCapacity, fifoSweeping mode in SciChart.js v3.2 we specify a number of points
    // we want in the viewport. When the right edge of the viewport is reached, the series wraps around

    const fifoSweepingGap = GAP_POINTS
    const dataSeries1 = new XyDataSeries(wasmContext, {
      fifoCapacity: POINTS_LOOP,
      dataSeriesName: 'ECG',
      fifoSweeping: true,
      fifoSweepingGap,
    })
    const dataSeries2 = new XyDataSeries(wasmContext, {
      fifoCapacity: POINTS_LOOP,
      fifoSweeping: true,
      fifoSweepingGap,
    })
    const dataSeries3 = new XyDataSeries(wasmContext, {
      fifoCapacity: POINTS_LOOP,
      fifoSweeping: true,
      fifoSweepingGap,
    })
    const dataSeries4 = new XyDataSeries(wasmContext, {
      fifoCapacity: POINTS_LOOP,
      fifoSweeping: true,
      fifoSweepingGap,
    })

    // A pointmarker with lastPointOnly = true will be used for all series to mark the last point
    const pointMarkerOptions = {
      width: 7,
      height: 7,
      strokeThickness: 2,
      fill: appTheme.MutedSkyBlue,
      lastPointOnly: true,
    }
    const ECGLabel = new TextAnnotation({
      text: 'ECG',
      fontSize: 16,
      x1: 0.5,
      y1: 0,
      textColor: appTheme.ShinyGreen,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      xCoordinateMode: ECoordinateMode.Relative, // xCoordinateMode relative allows 0..1 to correspond to viewport left/right
      yCoordinateMode: ECoordinateMode.Relative,
    })
    const RESPLabel = new TextAnnotation({
      text: 'RESP',
      fontSize: 16,
      x1: 0.5,
      y1: 1.25,
      textColor: appTheme.VividSkyBlue,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      xCoordinateMode: ECoordinateMode.Relative, // xCoordinateMode relative allows 0..1 to correspond to viewport left/right
      yCoordinateMode: ECoordinateMode.Relative,
    })

    const PLETHLabel = new TextAnnotation({
      text: 'PLETH',
      fontSize: 16,
      x1: 0.5,
      y1: 2.6,
      textColor: appTheme.BrightYellow,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      xCoordinateMode: ECoordinateMode.Relative, // xCoordinateMode relative allows 0..1 to correspond to viewport left/right
      yCoordinateMode: ECoordinateMode.Relative,
    })
    sciChartSurface.annotations.add(ECGLabel, RESPLabel, PLETHLabel)

    // Create four RenderableSeries which render the data
    sciChartSurface.renderableSeries.add(
      new FastLineRenderableSeries(wasmContext, {
        yAxisId: yAxisHeartRate.id,
        strokeThickness: STROKE_THICKNESS,
        stroke: appTheme.ShinyGreen,
        dataSeries: dataSeries1,
        pointMarker: new EllipsePointMarker(wasmContext, {
          ...pointMarkerOptions,
          stroke: appTheme.VividOrange,
        }),
      })
    )

    sciChartSurface.renderableSeries.add(
      new FastLineRenderableSeries(wasmContext, {
        yAxisId: yAxisBloodPressure.id,
        strokeThickness: STROKE_THICKNESS,
        stroke: appTheme.VividSkyBlue,
        dataSeries: dataSeries2,
        pointMarker: new EllipsePointMarker(wasmContext, {
          ...pointMarkerOptions,
          stroke: appTheme.VividSkyBlue,
        }),
      })
    )

    sciChartSurface.renderableSeries.add(
      new FastLineRenderableSeries(wasmContext, {
        yAxisId: yAxisBloodVolume.id,
        strokeThickness: STROKE_THICKNESS,
        stroke: appTheme.BrightYellow,
        dataSeries: dataSeries3,
        pointMarker: new EllipsePointMarker(wasmContext, {
          ...pointMarkerOptions,
          stroke: appTheme.VividPink,
        }),
      })
    )

    let timerId: NodeJS.Timeout | undefined
    let respTimerId: NodeJS.Timeout | undefined
    let plethTimerId: NodeJS.Timeout | undefined

    // The following code is run once per timer-step to update the data in the charts
    // Here you would subsitute your own callback to receive data from your data feed or sensors
    const runUpdateDataOnTimeout = (vitalData: any) => {
      // console.log('data length -----', vitalData?.ecgData?.length)
      // console.log(ecgdataCopy.current);
  
    function processNextBatch() {
      const {
        ecgHeartRateArr,
        ecgXArray,
      } = getValuesFromData(ecgRead, {ecgData: ecgdataCopy.current, respData: [], plethData: []})

      if (ecgRead < ecgWrite) {
      dataSeries1.appendRange(ecgXArray, ecgHeartRateArr)
        ecgRead += ECG_STEPS;
        // Set a timeout to process the next batch
        ecgTimerId = setTimeout(processNextBatch, ECG_TIMEOUT);
      }
    }
    if(ecgTimerId) {
      clearTimeout(ecgTimerId);
    }
      processNextBatch();
  }

  const runUpdatePlethOnTimeOut = (vitalData: any) => {
    function processNextBatch() {
      const {
        bloodVolumeArr,
        plethXArray,
      } = getPlethValuesFromData(plethRead, {ecgData: [], respData: [], plethData: plethDataCopy.current})


      if (plethRead < plethWrite) {
        dataSeries3.appendRange(plethXArray, bloodVolumeArr)
        plethRead += PLETH_STEPS;
  
        // Set a timeout to process the next batch
        plethTimer = setTimeout(processNextBatch, PLETH_TIMEOUT);
      }

    }
    if(plethTimer) {
      clearTimeout(plethTimer);
    }
    processNextBatch();

  }

  const runUpdateRespOnTimeOut = (vitalData: any) => {

    function processNextBatch() {

      const {
        bloodPressureArr,
        respXArray,
      } = getRespValuesFromData(respRead, {ecgData: [], respData: respDataCopy.current, plethData: []})

      if (respRead < respWrite) {
        dataSeries2.appendRange(respXArray, bloodPressureArr)
        respRead += RESP_STEPS;
  
        // Set a timeout to process the next batch
        respTimer = setTimeout(processNextBatch, RESP_TIMEOUT);
      }
    }
    if(respTimer) {
      clearTimeout(respTimer);
    }
    processNextBatch();
  }




    const handlePlethStop = () => {
      clearTimeout(plethTimerId)
      plethTimerId = undefined
    }

    const handlePlethStart = (vitalData: any) => {
      // if (plethTimerId) {
      //   handlePlethStop()
      // }
      runUpdatePlethOnTimeOut(vitalData)
    }

    const handleRespStop = () => {
      clearTimeout(respTimerId)
      respTimerId = undefined
    }

    const handleRespStart = (vitalData: any) => {
      // if (respTimerId) {
      //   handleRespStop()
      // }
      runUpdateRespOnTimeOut(vitalData)
    }

    const handleStop = () => {
      clearTimeout(timerId)
      timerId = undefined
    }

    const handleStart = (vitalData: any) => {
      // if (timerId) {
      //     handleStop();
      // }
      runUpdateDataOnTimeout(vitalData)
    }

    return {
      sciChartSurface,
      wasmContext,
      controls: {
        handleStart,
        handleRespStart,
        handlePlethStart,
        handleStop,
        handlePlethStop,
        handleRespStop,
        runUpdateDataOnTimeout,
      },
    }
  }




  const handlePubnubEvent = (data: any) => {
    if (data.message?.device === 'PhilipsMX550') {
      console.log('currentDate and time -----> ',  new Date(), '\n\n');
      const vitals = data?.message?.updatedVitals
      // console.log(data.message.updatedVitals);
      const ecgWave = vitals.filter((data: any) => {
        return data.code === 'ECG'
      })
      const plethWave = vitals.filter((data: any) => {
        return data.code === 'PLETH'
      })
      const respWave = vitals.filter((data: any) => {
        return data.code === 'RESP'
      })
      setMonitoringInfo((_prev: any) => {
        return vitals
          ?.map((entry: any) => {
            return {
              key: entry?.code,
              value: entry?.value,
            }
          })
          .filter((entry: any) => {
            return (
              entry.key !== 'ECG' &&
              entry.key !== 'RESP' &&
              entry.key !== 'PLETH'
            )
          })
          .reduce((result: any, item: any) => {
            result[item.key] = item.value
            return result
          }, {})
      })
      ecgdataCopy.current = ecgdataCopy.current.concat(
        JSON.parse(ecgWave[0].value)
      )
      respDataCopy.current = respDataCopy.current.concat(
        JSON.parse(respWave[0].value)
      )
      plethDataCopy.current = plethDataCopy.current.concat(
        JSON.parse(plethWave[0].value)
      )

      ecgWrite = ecgWrite + JSON.parse(ecgWave[0].value)?.length;
      plethWrite = plethWrite + JSON.parse(plethWave[0].value)?.length;
      respWrite = respWrite + JSON.parse(respWave[0].value)?.length;
     
      console.log("ECG", JSON.parse(ecgWave[0].value));
      console.log("RESP", JSON.parse(respWave[0].value));
      console.log("PLETH", JSON.parse(plethWave[0].value));

      // controlsRef.current?.handleStart({
      //   ecgData: ecgWave?.[0]?.value?.length ? JSON.parse(ecgWave[0].value) : [],
      //   respData: respWave?.[0]?.value?.length ?  JSON.parse(respWave[0].value) : [],
      //   plethData: plethWave?.[0]?.value?.length ?  JSON.parse(plethWave[0].value) : [],
      // })
      controlsRef.current?.handleStart({
        ecgData: ecgdataCopy.current?.length ? ecgdataCopy.current : [],
        respData: respWave?.[0]?.value?.length ?  JSON.parse(respWave[0].value) : [],
        plethData: plethWave?.[0]?.value?.length ?  JSON.parse(plethWave[0].value) : [],
      })
      // controlsRef.current?.handleRespStart({
      //   ecgData: ecgWave?.[0]?.value?.length ? JSON.parse(ecgWave[0].value) : [],
      //   respData: respWave?.[0]?.value?.length ?  JSON.parse(respWave[0].value) : [],
      //   plethData: plethWave?.[0]?.value?.length ?  JSON.parse(plethWave[0].value) : [],
      // })
      controlsRef.current?.handleRespStart({
        ecgData: ecgWave?.[0]?.value?.length ? JSON.parse(ecgWave[0].value) : [],
        respData: respDataCopy.current?.length ?  respDataCopy.current : [],
        plethData: plethWave?.[0]?.value?.length ?  JSON.parse(plethWave[0].value) : [],
      })
      // controlsRef.current?.handlePlethStart({
      //   ecgData: ecgWave?.[0]?.value?.length ? JSON.parse(ecgWave[0].value) : [],
      //   respData: respWave?.[0]?.value?.length ?  JSON.parse(respWave[0].value) : [],
      //   plethData: plethWave?.[0]?.value?.length ?  JSON.parse(plethWave[0].value) : [],
      // })
      controlsRef.current?.handlePlethStart({
        ecgData: ecgWave?.[0]?.value?.length ? JSON.parse(ecgWave[0].value) : [],
        respData: respWave?.[0]?.value?.length ?  JSON.parse(respWave[0].value) : [],
        plethData: plethDataCopy.current?.length ? plethDataCopy.current : [],
      })
      // if(ecgWave?.[0]?.value?.length) setEcgData((prev: any)=> JSON.parse(ecgWave[0].value));
      // if(plethWave?.[0]?.value?.length) setPlethWave( (prev: any)=> JSON.parse(plethWave[0].value));
      // if(respWave?.[0]?.value?.length) setRespWave( (prev: any)=> JSON.parse(respWave[0].value));
    } else if (data.message?.device === 'GE-VersaMed') {
      const vitals = data.message?.updatedVitals
      if (vitals.length) {
        const updatedValues = vitals.map((entry: any) => {
          return {
            value: entry?.value ?? '--',
            key: entry?.code,
          }
        })
        setOtherVitalsState(updatedValues)
      }
    } else if (data.message?.device === 'Graseby2100-1') {
      const vitals = data.message?.updatedVitals
      if (vitals.length) {
        const updatedValues = vitals.map((entry: any) => {
          return {
            value: entry?.value ?? '--',
            key: entry?.code,
          }
        })
        setSyringePumpState(updatedValues)
      }
    }
  }

  // console.log('monitoringinfo --------->', monitoringinfo)

  const timerInterval = useRef<any>(null)
  const phillipsInterval = useRef<any>(null);

  const dummyDataView = () => {
    phillipsInterval.current =  setInterval(()=> {
        handlePubnubEvent(dummyData);
    }, 1000)
  }

  useEffect(()=> {
        // dummyDataView();
    return ()=> {

        clearInterval(phillipsInterval.current);
    }
}, [ecg1Data.length])



  React.useEffect(() => {
    let autoStartTimerId: NodeJS.Timeout

    SciChartSurface.useWasmFromCDN()
    // pusher.subscribe([`VITALS.${orderId}`], handlePubnubEvent)
    const chartInitializationPromise = drawExample()
      .then((res) => {
        sciChartSurfaceRef.current = res.sciChartSurface
        controlsRef.current = res.controls
        // autoStartTimerId = setTimeout(()=> res.controls.handleStart({ecgData: ecg1Data, respData: respWave, plethData: plethWave}), 0);
      })
      .catch((err) => console.log(err))
      // if(pusher) {
        setTimeout(()=> {
          pusher.subscribe([`VITALS.5G.${patientOrderId}`], handlePubnubEvent)
        }, 200)
      // }
    // pusher.subscribe([`VITALS.5G.`], handlePubnubEvent)
    // Delete sciChartSurface on unmount component to prevent memory leak
    return () => {
      // check if chart is already initialized
      if (sciChartSurfaceRef.current) {
        clearTimeout(autoStartTimerId)
        controlsRef.current?.handleStop()
        controlsRef.current?.handlePlethStop()
        controlsRef.current?.handleRespStop()
        sciChartSurfaceRef.current.delete()
        return
      }

      // else postpone deletion
      chartInitializationPromise.then(() => {
        clearTimeout(autoStartTimerId)
        controlsRef.current?.handleStop()
        controlsRef.current?.handlePlethStop()
        controlsRef.current?.handleRespStop()
        sciChartSurfaceRef.current?.delete()
        return
      })

      clearTimeout(ecgTimerId);
      clearTimeout(respTimer);
      clearTimeout(plethTimer);

      ecgdataCopy.current = [];
      respDataCopy.current = [];
      plethDataCopy.current = [];
      ecgWrite =0;
      ecgRead =0;
      respRead = 0;
      respWrite =0;
      plethRead =0;
      plethWrite =0;

    }
  }, [])

  return (
    <div className='h-full w-full' id='vitalSignsId'>
      <div className='ml-1 mr-1 h-full w-full bg-[#F7F0FF]'>
        <div
          className='h-full border-gray-400 bg-purple-600 shadow-inner'
        >
          <ChartWrapper>
            <div
              className='absolute left-1 top-3 z-10 cursor-pointer'
              onClick={() => toggleFullscreen('vitalSignsId')}
            >
              <Icon icon='ooui:full-screen' color='white' />
            </div>
            <div style={{ display: 'flex', height: '100%' }}>
              <div
                id={divElementId}
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #364ba0',
                  borderRight: 'none',
                }}
                // className={classes.VitalSigns}
              />
              <div className='flex h-full w-1/4 flex-col justify-between'>
                <InfoBox
                  style={{
                    color: appTheme.ShinyGreen,
                    background: theme.colors['bg-black'],
                    borderTop: '1px solid #364ba0',
                  }}
                >
                  <IbRow1 className='justify-between'>
                    <IbRow1Col1 className='text-xs'>HR</IbRow1Col1>
                    <IbRow2Col2 className='text-xs'>
                      {'bpm'}
                    </IbRow2Col2>
                  </IbRow1>
                  <IbRow2>
                    <IbRow2Col1>
                      <div className='mt-4 text-2xl font-bold'>{monitoringinfo?.['Heart Rate'] ?? '100'}</div>
                    </IbRow2Col1>
                  </IbRow2>
                </InfoBox>
                <InfoBox
                  style={{
                    color: appTheme.Red,
                    justifyContent: 'space-between',
                    background: theme.colors['bg-black'],
                  }}
                >
                  <IbRow1 className='justify-between'>
                    <IbRow1Col1>NIBP</IbRow1Col1>
                    <IbRow2Col2 className='text-xs'>
                      {'mmHg'}
                    </IbRow2Col2>
                  </IbRow1>
                  <IbRow2>
                    <IbRow2Col2>
                      <div className='mb-4 text-lg font-semibold'>
                        {monitoringinfo?.['niSys']} /{' '}
                        {monitoringinfo?.['niDia']}
                      </div>
                    </IbRow2Col2>
                  </IbRow2>
                </InfoBox>
                <InfoBox
                  style={{
                    color: appTheme.BrightYellow,
                    background: theme.colors['bg-black'],
                  }}
                >
                  <IbRow1 className='justify-between'>
                    <IbRow1Col1 className='text-xs'>SPO2</IbRow1Col1>
                    <IbRow2Col2 className='text-xs'>
                      {'%'}
                    </IbRow2Col2>
                    {/* <IbRow1Col2>
                      ML 100
                      <br />
                      %**** 55
                    </IbRow1Col2> */}
                  </IbRow1>
                  <IbRow2 className='items-center align-center flex justify-center'>
                    <IbRow2Col2 className='mt-4'>
                      <div className='mb-4 text-2xl font-semibold'>{monitoringinfo?.['SpO2']}</div>
                    </IbRow2Col2>
                  </IbRow2>
                </InfoBox>
                <InfoBox
                  style={{
                    color: appTheme.VividTeal,
                    background: theme.colors['bg-black'],
                  }}
                >
                  <IbRow1 className='justify-between'>
                    <IbRow1Col1>
                      {'RR (Cardiac)'}
                      {/* <div style={{ fontSize: '8px' }}>rpm</div> */}
                    </IbRow1Col1>
                    <IbRow2Col2 className='text-xs'>
                      {'bpm'}
                    </IbRow2Col2>
                    {/* <IbRow1Col2>18:06</IbRow1Col2> */}
                  </IbRow1>
                  <IbRow2>
                    {/* <IbRow2Col1
                                      >
                                          <div>
                                              71-
                                              <br />
                                              RESP
                                          </div>
                                      </IbRow2Col1> */}
                    <IbRow2Col2 className='mb-4 text-2xl font-semibold'>{monitoringinfo?.['RR']}</IbRow2Col2>
                  </IbRow2>
                </InfoBox>
              </div>
            </div>
          </ChartWrapper>
        </div>
      </div>
    </div>
  )
}

const PatientMonitoring = memo(_PatientMonitoring);

export default PatientMonitoring
